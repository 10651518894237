<template>
<b-container fluid>
  <b-row class="text-white bg-kpmg-cobalt-blue py-4 mb-4">
    <b-col>
      <h1 class="kstyle">News filter</h1>
    </b-col>
  </b-row>
  <b-row>
  <b-container>
    <b-row class="mb-4">
      <b-col >
        <div v-if="active">
          <b-spinner small variant="success" type="grow" label="Spinning"></b-spinner><small>live updates on</small>
        </div>
        <b-card class="mb-3">
          <b-form inline>
            <label for="startdate">Start date</label>
            <b-form-datepicker id="startdate" v-model="startDate" class="mb-2 mr-4 ml-2"></b-form-datepicker>
            <label for="enddate">End date</label>
            <b-form-datepicker id="enddate" v-model="endDate" class="mb-2 ml-2 mr-4"></b-form-datepicker>
            <b-button v-b-toggle.sidebar-publishers variant="outline-primary" class="mb-2">
              Sources: {{publishersSelected.length}} out of {{publishersOptions.length}}
            </b-button>
          </b-form>
          <b-sidebar id="sidebar-publishers" title="Sources" backdrop backdrop-variant="transparent" shadow width="95%">
            <div class="px-3 py-2">
            <b-container>
              <b-row>
                <b-col>
                  <b-form-group horizontal>
                    <b-input-group>
                      <b-form-input v-model="searchSource" placeholder="Type to search source" />
                        <b-btn @click="searchSource = ''">clear</b-btn>
                    </b-input-group>
                  </b-form-group>
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="publishersSelected"
                    :options="publishersOptionsShow"
                    name="publishers"
                    style="column-count: 2;"
                    stacked
                  ></b-form-checkbox-group>
                </b-col>
                <b-col>
                  <b-container>
                    <b-row>
                      <b-col>
                        <b-button variant="outline-primary" class="mb-4 mr-2" v-on:click="allPublishers">select all</b-button>
                        <b-button variant="outline-primary" class="mb-4 mr-2" v-on:click="nonePublishers">clear all</b-button>
                        <b-button v-b-toggle.sidebar-publishers variant="primary" class="mb-4 mr-2" v-on:click="reload">Apply</b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <h3>Quick add options</h3>
                        <div class="mb-4">Use buttons below to add groups of sources to your selection</div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col v-if="$config.BUILD === 'full'">
                        <div>
                          <div><strong>Expert-led curation</strong></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('level1')">RIC Level 1</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('level2')">RIC Level 2</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('accounting')">Accounting</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('clients')">Clients</b-button></div>
                        </div>
                        <div>
                          <div><strong>Core</strong></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('ukcore')">UK Core</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('eucore')">EU Core</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('intcore')">International Core</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('uscore')">US Core</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('commodities')">Commodities</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('dataprivacy')">Data privacy</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('energy')">Energy and Water</b-button></div>
                          <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('lifescience')">Life sciences</b-button></div>
                        </div>
                      </b-col>
                      <b-col>
                        <div><strong>Countries</strong></div>
                        <div v-for="country in countries" :key="country">
                          <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="selectCountry(country)">{{ country }}</b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
            </div>
          </b-sidebar>

          <!-- FILTER BY TOPICS -->
          <b-form-checkbox v-model="filterByTopics" name="check-button" switch>
            Filter by topics
          </b-form-checkbox>
          <div v-if="filterByTopics">
          <b-button @click="isSidebarTopicsVisible = !isSidebarTopicsVisible" variant="outline-primary" class="mb-2">
              Topics: {{topicsSelected.length}} out of {{topicsOptions.length}}
          </b-button>
          <b-sidebar id="sidebar-topics" v-model="isSidebarTopicsVisible" title="Topics" backdrop backdrop-variant="transparent" shadow width="95%">
            <div class="px-3 py-2">
              <div>
                <b-button variant="outline-primary" class="mb-2 mr-2" v-on:click="allTopics">all</b-button>
                <b-button variant="outline-primary" class="mb-2 mr-2" v-on:click="noneTopics">clear</b-button>
                <b-button v-b-toggle.sidebar-topics variant="primary" class="mb-2 mr-2" v-on:click="reload">Apply</b-button>
              </div>
              <b-form-group horizontal>
                <b-input-group>
                  <b-form-input v-model="searchTopic" placeholder="Type to search topic" />
                    <b-btn @click="searchTopic = ''">clear</b-btn>
                </b-input-group>
              </b-form-group>
              <div v-if="renderTopics">
                <div class="mb-4" v-for="group in topicsByGroupOptionsShow" :key="'topic-group-' + group.id">
                  <div v-if="group.regtopics.length > 0">
                    <div><h3><b-form-checkbox v-model="groupsSelected" :value="group.id" name="check-button">{{ group.name }}</b-form-checkbox></h3></div>
                    <div v-for="topic in group.regtopics" :key="'topic-' + topic.id">
                      <b-form-checkbox v-model="topicsSelected" :value="topic.id" name="check-button">
                        {{ topic.name }}
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-sidebar>
          </div>

          <!-- FILTER BY TAGS -->
          <b-form-checkbox v-model="filterByTags" name="check-button" switch>
            Filter by types
          </b-form-checkbox>
          <div v-if="filterByTags">
          <b-button v-b-toggle.sidebar-tags variant="outline-primary" class="mb-2">
              Types: {{tagsSelected.length}} out of {{tagsOptions.length}}
          </b-button>
          <b-sidebar id="sidebar-tags" title="Types" backdrop backdrop-variant="transparent" shadow width="25%">
            <div class="px-3 py-2">
              <div>
                <b-button variant="outline-primary" class="mb-2 mr-2" v-on:click="allTags">all</b-button>
                <b-button variant="outline-primary" class="mb-2 mr-2" v-on:click="noneTags">clear</b-button>
                <b-button v-b-toggle.sidebar-tags variant="primary" class="mb-2 mr-2" v-on:click="reload">Apply</b-button>
              </div>
              <b-form-group label="Types" class="mb-4">
                <b-form-checkbox-group
                  id="checkbox-group-3"
                  v-model="tagsSelected"
                  :options="tagsOptions"
                  name="tags"
                  stacked
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </b-sidebar>
          </div>

          <div class="mt-4">
            <b-form inline>
              <b-button class="mr-4" variant="primary" v-on:click="reload">Apply</b-button>
              <span class="mr-4">&nbsp;|&nbsp;</span>
              <b-form-select class="mr-2" v-model="presets" :options="presetsOptions"></b-form-select>
              <b-form-input v-if="presets === 'new'" class="mr-2" v-model="presetName" type="text" placeholder="name your new filter"></b-form-input>
              <b-button v-if="presetName.length > 2" class="mr-2" variant="outline-primary" v-on:click="presetAdd">Save</b-button>
              <span v-if="presets !== 'new' && presets !== null" >
                <b-button class="mr-2" variant="outline-primary" v-on:click="presetApply">Apply and load</b-button>
                <b-button class="mr-2" variant="outline-primary" v-on:click="presetChange">Save (overwrite)</b-button>
                <b-button class="mr-2" variant="outline-primary" v-on:click="presetDelete">Delete</b-button>
              </span>
              <b-button variant="outline-primary" v-b-modal.modal-center>Help</b-button>
              <b-modal ok-only id="modal-center" centered title="Personal filters">
                <ul>
                  <li>Personal filters are saved locally in your browser.</li>
                  <li>They will not sync between devices and they will be lost when you clear cache in your browser.</li>
                  <li>They are not visible to other users.</li>
                </ul>
              </b-modal>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mb-4" v-if="!firstRun">
      <b-col>
        <b-pagination
          v-model="page"
          :limit=10
          :total-rows="totalRows"
          :per-page="pageSize"
        />
        Total news: {{totalRows}}
        <span v-if="!downloading">
          <b-button variant="outline-primary" class="mb-2" @click="download">download</b-button>
        </span>
        <span v-if="downloading" class="mx-2 mb-2">
          <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
          downloading news: {{newsDownloadedCount}}
        </span>
        <span v-if="!loadingNews">
          <b-card class="mb-3" v-for="item in news" :key="'publication-' + item.id">
            <publication
              :hideLink="false"
              :propItem="item"
              :key="item.id"
              :username="user.username"
              :tagsProp=tags
              :permissionAddArticle="permissionAddArticle"
              :permissionAddRisk="permissionAddRisk"
              :permissionAddTag="permissionAddTag"
              :permissionArticlesWip="permissionArticlesWip"
              :permissionDeletePublication="permissionDeletePublication"
              :permissionInsights="permissionInsights"
              :permissionReadTagsPanel="permissionReadTagsPanel"
            />
          </b-card>
        </span>
        <span v-show="loadingNews">
          <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
        </span>
        <b-pagination
          v-model="page"
          :limit=10
          :total-rows="totalRows"
          :per-page="pageSize"
        />
      </b-col>
    </b-row>
  </b-container>
  </b-row>
</b-container>
</template>

<script>
import _ from 'lodash'
import zipcelx from 'zipcelx'
import ac from '../libs/accesscontrol'
import moment from 'moment'
import Publication from '@/components/PublicationNew'
import sourcesGroups from '../libs/sourcesGroups.js'

export default {
  components: {
    Publication
  },
  computed: {
    config: function () {
      return {
        endDate: moment(this.endDate).add(1, 'days'),
        filterByTopics: this.filterByTopics,
        filterByTags: this.filterByTags,
        publishers: this.publishersSelected,
        tags: this.tagsSelected,
        topics: this.topicsSelected,
        startDate: this.startDate
      }
    },
    publishersOptionsShow: function () {
      let result = this.publishersOptions
      if (this.searchSource.length > 0) {
        result = _.filter(result, x => {
          return x.text.toUpperCase().includes(this.searchSource.toUpperCase())
        })
      }
      return result
    },
    topicsOptionsShow: function () {
      let result = this.topicsOptions
      if (this.searchTopic.length > 0) {
        result = _.filter(result, x => {
          return x.text.toUpperCase().includes(this.searchTopic.toUpperCase())
        })
      }
      return result
    },
    topicsByGroupOptionsShow: function () {
      const groups = this.topicsByGroup
      let result = []
      if (this.searchTopic.length > 1) {
        groups.forEach(group => {
          const row = {
            name: group.name,
            id: group.id,
            regtopics: []
          }
          row.regtopics = _.filter(group.regtopics, x => {
            return x.name.toUpperCase().includes(this.searchTopic.toUpperCase())
          })
          result.push(row)
        })
      } else {
        result = groups
      }
      return result
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  beforeDestroy: function () {
    this.active = false
  },
  created: async function () {
    document.title = "KPMG Regulatory Horizon: News Filter"
    this.$store.commit('setBrand', 'Regulatory Horizon')
    this.$stat.log({ page: 'news filter', action: 'open news filter' })
    const favTopics = window.localStorage.getItem('frmCloudFavTopics')
    if (favTopics) {
      this.favTopics = JSON.parse(favTopics)
    }
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = true
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionArticlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permissionReadAnalytics = ac.can(this.user.acgroups).readAny('analytics').granted
    this.permissionInsights = true
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    if (this.$config.BUILD === 'external') {
      this.permissionAddRisk = false
      this.permissionAddTag = false
      this.permissionDeletePublication = false
      this.permissionInsights = ac.can(this.user.acgroups).readAny('article').granted
      this.permissionReadAnalytics = false
      this.permissionReadTagsPanel = false
    }
    let apiName = 'cosmos'
    const tags = this.$Amplify.API.get(apiName, '/tags')
    const sources = this.$Amplify.API.get(apiName, '/sources')
    const topics = this.$Amplify.API.get(apiName, '/topics/simple')
    const topicsByGroup = this.$Amplify.API.get(apiName, '/topics/bygroup')
    let response = await Promise.all([tags, sources, topics, topicsByGroup])
    this.sources = response[1]
    let publishers = _.uniq(this.sources.map(source => source.publisher)).sort()
    this.countries = _.uniq(this.sources.map(source => source.countryName)).sort()
    this.tags = response[0]
    this.publishers = publishers
    let topicsTemp = response[2]
    _.remove(topicsTemp, x => {
      return x.name.includes('ARCHIVED')
    })
    this.topics = topicsTemp
    this.topicsByGroup = response[3]
    this.tagsOptions = _.map(this.tags, x => { return { text: x.name, value: x.id } })
    this.tagsSelected = _.map(this.tags, x => { return x.id })
    this.topicsOptions = _.map(this.topics, x => { return { text: x.name, value: x.id } })
    this.publishers.forEach(element => this.publishersOptions.push({ text: element, value: element }))
    this.publishersSelected = response[1]
    if (this.favTopics) {
      this.topicsSelected = this.favTopics
    } else {
      this.topicsSelected = _.map(this.topics, x => { return x.id })
    }
    const advFilterConfigString = window.localStorage.getItem('frmCloudAdvFilterConfig')
    if (advFilterConfigString) {
      const advFilterConfig = JSON.parse(advFilterConfigString)
      this.filterByTopics = advFilterConfig.filterByTopics
      this.filterByTags = advFilterConfig.filterByTags
      this.publishersSelected = advFilterConfig.publishers
      this.tagsSelected = advFilterConfig.tags
      this.topicsSelected = advFilterConfig.topics
    }
    if (this.$route.query.topic) {
      this.topicsSelected = this.$route.query.topic.toString().split(",").map(Number)
      this.filterByTopics = true
      this.filterByTags = false
    }
    if (this.$route.query.publisher) {
      this.publishersSelected = [this.$route.query.publisher]
    }
    this.presetsOptions = [
      { value: null, text: 'Personal filters' },
      { value: 'new', text: 'new filter' }
    ]
    const presetsStoreString = window.localStorage.getItem('frmCloudFilterPresets')
    if (presetsStoreString) {
      this.presetsStore = JSON.parse(presetsStoreString)
      _.each(this.presetsStore, row => {
        this.presetsOptions.push({ value: row.value, text: row.name })
      })
    }
    this.load()
  },
  data () {
    return {
      active: false,
      countries: [],
      delay: 60 * 1000,
      downloading: false,
      endDate: moment().format('YYYY-MM-DD'),
      filterByTopics: false,
      filterByTags: false,
      firstRun: true,
      favTopics: null,
      groupsSelected: [],
      groupsSelectedOld: [],
      isSidebarTopicsVisible: false,
      isSettingUp: true,
      latest: 0,
      loadingNews: false,
      news: [],
      newsDownloadedCount: 0,
      permissionAddRisk: false,
      permissionAddTag: false,
      permissionArticlesWip: false,
      permissionDeletePublication: false,
      permissionReadAnalytics: false,
      permissionReadTagsPanel: false,
      presets: null,
      presetName: '',
      presetsOptions: [],
      presetsStore: [],
      publishers: [],
      publishersSelected: [],
      publishersOptions: [],
      renderTopics: true,
      searchSource: '',
      searchTopic: '',
      startDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
      sources: [],
      tags: [],
      tagsSelected: [],
      tagsOptions: [],
      topics: [],
      topicsSelected: [],
      topicsOptions: [],
      totalRows: 0,
      pageSize: 20,
      page: 1
    }
  },
  methods: {
    download: async function () {
      this.$logger.debug('loading started')
      this.downloading = true
      try {
        const params = {
          body: {
            endDate: moment(this.endDate).add(1, 'days'),
            filterByTopics: this.filterByTopics,
            filterByTags: this.filterByTags,
            page: 1,
            pageSize: 200,
            publishers: this.publishersSelected,
            tags: this.tagsSelected,
            topics: this.topicsSelected,
            startDate: this.startDate
          }
        }
        let news = []
        let response = []
        do {
          response = await this.$Amplify.API.post('cosmos', `/news/filter/version/1`, params)
          news = _.concat(news, response.results)
          params.body.page++
          this.newsDownloadedCount = news.length
          await this.$nextTick()
        } while (response.results.length === params.body.pageSize)
        let data = []
        const headings = [
          { value: 'NewsId', type: 'string' },
          { value: 'Title', type: 'string' },
          { value: 'Date', type: 'string' },
          { value: 'Source', type: 'string' },
          { value: 'Details', type: 'string' },
          { value: 'Link', type: 'string' },
          { value: 'Country', type: 'string' },
          { value: 'Language', type: 'string' },
          { value: 'Publisher', type: 'string' },
          { value: 'Topics', type: 'string' },
          { value: 'Risks', type: 'string' },
          { value: 'Tags', type: 'string' },
          { value: 'EventTitle', type: 'string' },
          { value: 'EventComments', type: 'string' },
          { value: 'EventDate', type: 'string' }
        ]
        data.push(headings)
        for (let i = 0, len = news.length; i < len; i++) {
          let row = [
            { value: news[i].id, type: 'string' },
            { value: news[i].name, type: 'string' },
            { value: moment(news[i].date).format('YYYY-MM-DD'), type: 'string' },
            { value: news[i].source, type: 'string' },
            { value: news[i].body, type: 'string' },
            { value: news[i].link, type: 'string' },
            { value: news[i].countryName, type: 'string' },
            { value: news[i].langName, type: 'string' },
            { value: news[i].publisher, type: 'string' },
            { value: news[i].regtopicsName, type: 'string' },
            { value: news[i].risksName, type: 'string' },
            { value: news[i].tagsName, type: 'string' }
          ]
          if (news[i].events.length > 0) {
            row.push({ value: news[i].events[0].name, type: 'string' })
            row.push({ value: news[i].events[0].comments, type: 'string' })
            row.push({ value: moment(news[i].events[0].date).format('YYYY-MM-DD'), type: 'string' })
          } else {
            row.push({ value: '', type: 'string' })
            row.push({ value: '', type: 'string' })
            row.push({ value: '', type: 'string' })
          }
          data.push(row)
        }
        const config = { filename: 'newsAdvancedFilter', sheet: { data: data } }
        zipcelx(config)
      } catch (e) {
        this.$logger.warn('loading error' + e)
      }
      this.downloading = false
    },
    presetAdd: function () {
      const newItem = {
        name: this.presetName,
        value: Date.now(),
        config: this.config
      }
      this.presetsStore.push(newItem)
      this.presetsStore = _.orderBy(this.presetsStore, ['name'])
      this.presetsOptions = [
        { value: null, text: 'Personal filters' },
        { value: 'new', text: 'new filter' }
      ]
      _.each(this.presetsStore, row => {
        this.presetsOptions.push({ value: row.value, text: row.name })
      })
      window.localStorage.setItem('frmCloudFilterPresets', JSON.stringify(this.presetsStore))
      this.$stat.log({ page: 'news filter', action: 'add preset filter', payload: this.presetsStore })
      this.presetName = ''
      this.presets = newItem.value
    },
    presetApply: function () {
      const preset = _.find(this.presetsStore, { value: this.presets })
      this.filterByTopics = preset.config.filterByTopics
      this.filterByTags = preset.config.filterByTags
      this.publishersSelected = preset.config.publishers
      this.tagsSelected = preset.config.tags
      this.topicsSelected = preset.config.topics
      this.$stat.log({ page: 'news filter', action: 'apply preset filter' })
      this.load()
    },
    presetChange: function () {
      _.each(this.presetsStore, row => {
        if (row.value === this.presets) {
          row.config = this.config
        }
      })
      _.each(this.presetsOptions, row => {
        if (row.value === this.presets) {
          row.config = this.config
        }
      })
      window.localStorage.setItem('frmCloudFilterPresets', JSON.stringify(this.presetsStore))
      this.$stat.log({ page: 'news filter', action: 'change preset filter', payload: this.presetsStore })
      this.presets = null
    },
    presetDelete: function () {
      _.remove(this.presetsStore, row => {
        return row.value === this.presets
      })
      this.presetsOptions = [
        { value: null, text: 'Personal filters' },
        { value: 'new', text: 'new filter' }
      ]
      _.each(this.presetsStore, row => {
        this.presetsOptions.push({ value: row.value, text: row.name })
      })
      window.localStorage.setItem('frmCloudFilterPresets', JSON.stringify(this.presetsStore))
      this.$stat.log({ page: 'news filter', action: 'delete preset filter' })
      this.presets = null
    },
    load: async function () {
      this.$logger.debug('loading started')
      this.loadingNews = true
      try {
        let apiName = 'cosmos'
        let path = `/news/filter/version/1`
        let params = {
          body: {
            endDate: moment(this.endDate).add(1, 'days'),
            filterByTopics: this.filterByTopics,
            filterByTags: this.filterByTags,
            page: this.page,
            pageSize: this.pageSize,
            publishers: this.publishersSelected,
            tags: this.tagsSelected,
            topics: this.topicsSelected,
            startDate: this.startDate
          }
        }
        let response = await this.$Amplify.API.post(apiName, path, params)
        this.$logger.debug(response)
        this.news = response.results
        this.totalRows = response.total
        this.$logger.debug(this.news)
        this.firstRun = false
        this.loadingNews = false
        this.renderTopics = true
        window.localStorage.setItem('frmCloudAdvFilterConfig', JSON.stringify(params.body))
        if (this.page === 1) {
          this.active = true
          this.latest = this.news[0].id
          _.delay(this.refresh, this.delay)
        } else {
          this.active = false
        }
      } catch (e) {
        this.$logger.warn('loading error' + e)
      }
      this.isSettingUp = false
    },
    allPublishers: function () {
      this.publishersSelected = this.publishers
    },
    allTags: function () {
      this.tagsSelected = _.map(this.tags, x => { return x.id })
    },
    allTopics: function () {
      this.topicsSelected = _.map(this.topics, x => { return x.id })
    },
    selectCountry: function (country) {
      const publishers = []
      _.each(this.sources, source => {
        if (source.countryName === country) {
          publishers.push(source.publisher)
        }
      })
      this.publishersSelected = _.uniq(_.concat(this.publishersSelected, _.uniq(publishers)))
    },
    custom: function (selection) {
      const groups = sourcesGroups
      let newSelection = _.uniq(_.concat(this.publishersSelected, groups[selection]))
      this.publishersSelected = newSelection
    },
    nonePublishers: function () {
      this.publishersSelected = []
    },
    noneTags: function () {
      this.tagsSelected = []
    },
    noneTopics: function () {
      this.topicsSelected = []
    },
    refresh: async function () {
      if (!this.active) {
        return
      }
      this.$logger.debug('refresh started')
      try {
        let apiName = 'cosmos'
        let path = `/news/filter/version/1`
        let params = {
          body: {
            endDate: moment(this.endDate).add(1, 'days'),
            filterByTopics: this.filterByTopics,
            filterByTags: this.filterByTags,
            page: 1,
            pageSize: this.pageSize,
            publishers: this.publishersSelected,
            tags: this.tagsSelected,
            topics: this.topicsSelected,
            startDate: this.startDate
          }
        }
        let response = await this.$Amplify.API.post(apiName, path, params)
        this.$logger.debug(response)
        this.totalRows = response.total
        let news = response.results
        _.forEach(news, (x) => {
          if (x.id > this.latest) {
            x.refreshed = true
          }
        })
        this.news = news
      } catch (e) {
        this.$logger.warn('refresh error: ' + e)
      }
      if (this.active) {
        _.delay(this.refresh, this.delay)
      }
    },
    reload: function () {
      this.page = 1
      this.totalRows = 0
      this.collapseVisible = false
      this.load()
    }
  },
  watch: {
    filterByTopics: function () {
      if (this.filterByTopics && !this.isSettingUp) {
        this.isSidebarTopicsVisible = true
      }
    },
    groupsSelected: function () {
      const added = this.groupsSelected.filter(x => !this.groupsSelectedOld.includes(x))
      const removed = this.groupsSelectedOld.filter(x => !this.groupsSelected.includes(x))
      if (added.length > 0) {
        this.topicsByGroup.forEach(group => {
          if (group.id === added[0]) {
            const toAdd = group.regtopics.map(x => x.id)
            this.topicsSelected = _.union(this.topicsSelected, toAdd)
          }
        })
      }
      if (removed.length > 0) {
        this.topicsByGroup.forEach(group => {
          if (group.id === removed[0]) {
            const toRemove = group.regtopics.map(x => x.id)
            this.topicsSelected = _.difference(this.topicsSelected, toRemove)
          }
        })
      }
      this.groupsSelectedOld = this.groupsSelected
    },
    page: 'load'
  }
}
</script>
